@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        font-family: Wotfard;
        @apply text-gray-5;
    }
}

@layer utilities {
    .animation-pause {
      animation-play-state: paused;
    }
  }

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: 300;
    src: local('Wotfard'), url('fonts/wotfard-light-webfont.woff2') format('opentype');
}

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: 400;
    src: local('Wotfard'), url('fonts/wotfard-regular-webfont.woff2') format('opentype');
}

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: 500;
    src: local('Wotfard'), url('fonts/wotfard-medium-webfont.woff2') format('opentype');
}

@font-face {
    font-family: 'Wotfard';
    font-style: normal;
    font-weight: 600;
    src: local('Wotfard'), url('fonts/wotfard-semibold-webfont.woff2') format('opentype');
}